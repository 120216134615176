<template>
  <main class="bg-gray-50">
    <div
      class="flex flex-col lg:flex-row items-center justify-between text-white" id="contact"
    >
      <!-- Image Section -->
      <div class="lg:w-1/2 flex justify-center lg:justify-end mb-10">
        <img
          src="../assets/images/contactus.png"
          alt="Architect image"
          class="w-3/4 lg:w-full"
        />
      </div>

      <!-- Text Section -->
      <div class="text-center lg:text-left mb-10 lg:mb-10 lg:w-1/2">
        <div class="flex items-center justify-center lg:justify-start gap-2 mb-4">
          <img class="h-10 w-10" src="../assets/images/gnobonelogo.png" alt="Brand logo" />
          <h2 class="text-sm text-blue font-semibold -ml-2 -mb-8">
              Contact Us
            </h2>
        </div>
         <h3 class="text-black font-extrabold text-3xl mt-4">Contact Us Today For <br /> Innovative Designs</h3>
         <p class="text-lg lg:text-sm text-[#9e9d9d] leading-relaxed mt-4">
          Reach out to us today to discuss how Gnobone Vision can <br> bring your next project to life.
         </p>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style scoped></style>
