<template>
    <main class="bg-white py-10">
      <!-- Section for architectural designs -->
      <div class="md:px-10 max-w-screen-2xl mx-auto px-4">
        <!-- Header -->
        <div class="flex items-center justify-center">
          <div class="text-center">
            <div class="flex items-center justify-center">
              <img
                class="h-10 w-10"
                src="../assets/images/gnobonelogo.png"
                alt="Brand logo"
              />
              <h2 class="text-sm text-blue font-semibold -mb-7">
                3D Exterior Projects
              </h2>
            </div>
          </div>
        </div>
  
        <!-- Slider for Designs -->
        <div class="md:px-4 max-w-screen-2xl mx-auto py-8">
          <div id="design-carousel" class="relative" data-carousel="slide">
            <!-- Carousel wrapper -->
            <div class="relative overflow-hidden rounded-lg lg:py-10">
              <!-- Carousel container -->
              <div
                class="flex gap-6 transition-transform duration-700 ease-in-out"
                :style="{
                  transform: `translateX(-${
                    activeIndex * (100 / cardsPerSlide)
                  }%)`,
                }"
              >
                <!-- Design Cards (converted to slider) -->
                <div
                  v-for="(design, index) in designs"
                  :key="design.id"
                  class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 min-w-[80%] sm:min-w-[50%] md:min-w-[33.33%] lg:min-w-[30%] cursor-pointer text-center py-4 px-4 rounded-lg shadow-lg transition-all duration-500 hover:scale-105 hover:shadow-xl"
                >
                  <!-- 3d Exterior Images -->
                  <img
                  :src="design.image"
                  alt="Design Image"
                  class="w-full h-60 object-cover rounded-md mb-4 transition-transform duration-300 hover:scale-105"
                  />
                </div>
              </div>
            </div>
  
            <!-- Slider controls -->
            <button
              type="button"
              class="absolute top-1/2 left-0 transform -translate-y-1/2 z-30 flex items-center justify-center h-10 w-10 bg-blue rounded-full"
              @click="prevSlide"
            >
              <svg
                class="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
            <button
              type="button"
              class="absolute top-1/2 right-0 transform -translate-y-1/2 z-30 flex items-center justify-center h-10 w-10 bg-blue rounded-full"
              @click="nextSlide"
            >
              <svg
                class="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </main>
  </template>
  
  <script>
  export default {
    data() {
      return {
        activeIndex: 0,
        cardsPerSlide: 3, // Adjust according to the number of cards per slide (3 cards in this case)
        designs: [
          {
            id: 1,
            image: require("../assets/images/3D1.jpg"),
          },
          {
            id: 2,
            image: require("../assets/images/3D3.jpg"),
          },
          {
            id: 3,
            image: require("../assets/images/3D_5 - Photo.jpg"),
          },
          {
            id: 4,
            image: require("../assets/images/3D_9 - Photo.jpg"),
          },
          {
            id: 5,
            image: require("../assets/images/3D 4BED TR KUNAPE_1 - Photo.jpg"),
          },
          {
            id: 6,
            image: require("../assets/images/3D 5BED_2 - Photo.jpg"),
          },
          {
            id: 7,
            image: require("../assets/images/3D MANSONETTE_1 - Photo.jpg"),
          },
          {
            id: 8,
            image: require("../assets/images/3D TERRACE RESORT_5 - Photo.jpg"),
          },
        ],
        slideInterval: null, // To store interval for automatic sliding
      };
    },
    mounted() {
      // Set up automatic slide change every 3 seconds
      this.slideInterval = setInterval(this.nextSlide, 3000);
    },
    beforeDestroy() {
      // Clear the interval when the component is destroyed
      clearInterval(this.slideInterval);
    },
    methods: {
      nextSlide() {
        if (
          this.activeIndex <
          Math.ceil(this.designs.length / this.cardsPerSlide) + 1
        ) {
          this.activeIndex++;
        } else {
          this.activeIndex = 0; // Go back to the first set of slides
        }
      },
      prevSlide() {
        if (this.activeIndex > 0) {
          this.activeIndex--;
        } else {
          this.activeIndex =
            Math.ceil(this.designs.length / this.cardsPerSlide) - 1; // Go to the last set of slides
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Custom styling */
  .carousel-wrapper {
    display: flex;
    gap: 6px;
    transition: transform 0.5s ease-in-out;
  }
  
  img {
    transition: transform 0.3s ease-in-out;
  }
  
  img:hover {
    transform: scale(1.05);
  }
  </style>
  