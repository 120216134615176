<template>
  <main class="bg-white">
    <div class="md:px-12 max-w-screen-2xl mx-auto py-10 relative z-10">
      <div class="bg-white shadow-2xl py-10 px-10">
        <!-- Header -->
        <div class="flex items-center justify-center">
          <div class="text-center">
            <div class="flex items-center justify-center">
              <img
                class="h-10 w-10"
                src="../assets/images/gnobonelogo.png"
                alt="Brand logo"
              />
              <h2 class="text-sm text-blue font-semibold -mb-7">
                Our Partners
              </h2>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col lg:flex-row items-center justify-between mt-14"
        >
          <img
            src="../assets/images/logo (1).jpg"
            alt=""
            class="h-16 w-16 object-contain"
          />
          <img
            src="../assets/images/nath logo.png"
            alt=""
            class="h-16 w-16 object-contain"
          />
          <img
            src="../assets/images/SDG NEW.png"
            alt=""
            class="h-16 w-16 object-contain"
          />
          <img
            src="../assets/images/nandohomes.png"
            alt=""
            class="h-16 w-16 object-contain"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>

<style scoped></style>
