<template>
  <main>
    <div
      class="relative bg-cover bg-center"
      :style="{
        backgroundImage: `url(${require('../assets/images/crop-architect-opening-blueprint.jpg')})`,
      }"
    >
      <div class="absolute inset-0 bg-black bg-opacity-50"></div>
      <div
        class="relative md:px-32 sm:px-12 max-w-screen-2xl mx-auto z-10 h-screen flex items-center justify-start text-white"
      >
        <!-- About Section -->
        <h1 class="text-2xl md:text-6xl font-bold">Our Services</h1>
      </div>
    </div>

    <!-- Our services -->
     <ourServices />

    <!-- Animations Layout -->
     <animationsProject />

    <!-- 3D Exterior -->
     <threeDExterior />

    <!-- Sketch Vidoes -->
     <sketchVideos />

    <!-- 3D Interior -->
     <threeDInterior />

    <!-- Interior Virtual Reality -->
     <interiorVirtualReality />
  </main>
</template>

<script>
import ourServices from '../components/ourServices.vue';
import animationsProject from '../components/animationsProjects.vue';
import threeDExterior from "../components/threeDExterior.vue";
import sketchVideos from "../components/sketchVidoes.vue";
import threeDInterior from '@/components/threeDInterior.vue';
import interiorVirtualReality from "../components/interiorVirtualReality.vue";

export default {
    components: {
    ourServices,
    animationsProject,
    threeDExterior,
    sketchVideos,
    threeDInterior,
    interiorVirtualReality
  },
};
</script>

<style scoped></style>
