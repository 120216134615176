<template>
  <main class="bg-black">
    <div
      class="relative max-w-screen-4xl mx-auto h-screen flex flex-col lg:flex-row items-center justify-between px-6 lg:px-32"
    >
      <!-- Video Background with Toggle -->
      <div class="absolute inset-0 overflow-hidden">
        <video
          v-show="currentVideo === 1"
          autoplay
          muted
          loop
          playsinline
          class="w-full h-full object-cover opacity-70"
        >
          <source
            src="https://res.cloudinary.com/dngbejxfo/video/upload/v1734010939/website_video-1_md0pis.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <!-- <video
          v-show="currentVideo === 2"
          autoplay
          muted
          loop
          playsinline
          class="w-full h-full object-cover opacity-70"
        >
          <source
            src="https://res.cloudinary.com/dngbejxfo/video/upload/v1731592045/Gnobone%20Visions/hero-video2_1_ol4f7g.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video> -->
        <div
          class="absolute inset-0 bg-blue-to-b from-transparent to-blue-900 opacity-70"
        ></div>
      </div>

      <!-- Main Content -->
      <div
        class="relative mt-28 z-10 text-center lg:text-left lg:w-3/4 space-y-6 lg:px-6"
      >
        <p
          class="text-lg lg:text-lg text-[#ffa200] md:w-full lg:w-1/3 rounded-full px-2 py-1 font-extrabold tracking-wide mb-1 text-shadow"
        >
          Welcome to
        </p>
        <h1
          class="text-3xl lg:text-6xl font-extrabold text-white leading-tight text-shadow"
        >
          Gnobone Visions
        </h1>
        <h4
          class="text-xl lg:text-3xl font-semibold text-[#ffa200] mt-2 mb-4 text-shadow"
        >
          {{ displayedText }}<span class="cursor">|</span>
        </h4>
        <p
          class="text-sm lg:text-lg text-white font-normal leading-relaxed text-shadow"
        >
          We specialize in crafting innovative architectural designs <br />
          that blend form and function to create inspiring, <br />
          unforgettable spaces.
        </p>
        <div class="mt-8">
          <button
            class="bg-transparent border-2 hover:border-none border-white hover:bg-[#859aca] hover:text-white shadow-lg text-white px-6 py-2 text-base font-semibold transition-transform transform hover:scale-110 focus:outline-none focus:ring-4 focus:ring-main/50"
          >
            Let's Talk
          </button>
        </div>
      </div>

      <!-- Floating Icons (Hidden on Mobile) -->
      <div
        class="absolute left-6 top-1/2 transform -translate-y-1/2 hidden md:flex flex-col space-y-4"
      >
        <div
          v-for="icon in leftIcons"
          :key="icon"
          class="floating hidden bg-white cursor-pointer w-10 h-10 rounded-full items-center justify-center transition-transform hover:scale-105 shadow-lg"
        >
          <span class="text-[#ffa200] text-lg">{{ icon }}</span>
        </div>
      </div>
      <div
        class="absolute right-6 top-1/2 cursor-pointer transform -translate-y-1/2 hidden md:flex flex-col space-y-4"
      >
        <div
          v-for="icon in rightIcons"
          :key="icon"
          class="floating hidden bg-white w-10 h-10 rounded-full items-center justify-center transition-transform hover:scale-105 shadow-lg"
        >
          <span class="text-[#ffa200] text-lg">{{ icon }}</span>
        </div>
      </div>
    </div>

    <!--Other sections implementation -->
    <!-- <ourServices /> -->
    <workProjects />
    <numbersRatings />
    <paymentPlan />
    <ourPartners />
    <youTube />
    <contactUs />
  </main>
</template>

<script>
import ourServices from "../components/ourServices.vue";
import workProjects from "../components/workProjects.vue";
import youTube from "../components/youTube.vue";
import contactUs from "../components/contactUs.vue";
import paymentPlan from "../components/paymentPlan.vue";
import numbersRatings from "../components/numbersRatings.vue";
import ourPartners from "../components/ourPartners.vue";

export default {
  components: {
    ourServices,
    workProjects,
    youTube,
    contactUs,
    paymentPlan,
    numbersRatings,
    ourPartners
  },
  data() {
    return {
      isMenuOpen: false,
      texts: [
        "CREATIVE ARCHITECTURAL DESIGN",
        "INNOVATIVE DESIGNS",
        "CREATING UNIQUE EXPERIENCES",
      ],
      displayedText: "",
      currentIndex: 0,
      currentVideo: 1,
      isErasing: false,
      speed: 22,
      delayBetweenWords: 1000,
      leftIcons: ["🏗️", "🌍"],
      rightIcons: ["📐", "🚀"],
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
  mounted() {
    this.typingEffect();
  },
  methods: {
    typingEffect() {
      const currentText = this.texts[this.currentIndex];
      if (!this.isErasing && this.displayedText.length < currentText.length) {
        // Typing effect: Add characters
        this.displayedText = currentText.substring(
          0,
          this.displayedText.length + 1
        );
        setTimeout(this.typingEffect, this.speed);
      } else if (this.isErasing && this.displayedText.length > 0) {
        // Erasing effect: Remove characters
        this.displayedText = currentText.substring(
          0,
          this.displayedText.length - 1
        );
        setTimeout(this.typingEffect, this.speed);
      } else if (
        !this.isErasing &&
        this.displayedText.length === currentText.length
      ) {
        // Pause before erasing
        setTimeout(() => {
          this.isErasing = true;
          this.typingEffect();
        }, this.delayBetweenWords);
      } else if (this.isErasing && this.displayedText.length === 0) {
        // Finished erasing, move to next text
        this.isErasing = false;
        this.currentIndex = (this.currentIndex + 1) % this.texts.length;
        this.typingEffect();
      }
    },
  },
};
</script>

<style scoped>
@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.cursor {
  animation: blink 0.7s step-start infinite;
}

.floating {
  animation: float 6s ease-in-out infinite;
}

.hover\:shadow-glow:hover {
  animation: glow 0.8s ease-in-out infinite alternate;
}

.floating:nth-child(2) {
  animation-duration: 5s;
}
.floating:nth-child(3) {
  animation-duration: 4s;
}
.floating:nth-child(4) {
  animation-duration: 7s;
}
/* .text-shadow {
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
}
.text-shadow-md {
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
} */
.glow-text {
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #ffa200, 0 0 20px #ffa200, 0 0 30px #ffa200;
  }
  to {
    text-shadow: 0 0 20px #ecb85d, 0 0 40px #ecb85d, 0 0 60px #ecb85d;
  }
}
</style>
