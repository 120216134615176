<template>
  <main>
    <section
      class="py-16 md:px-12 lg:px-2 p-4 max-w-screen-2xl mx-auto text-white mb-12"
    >
      <div class="flex flex-col lg:flex-row gap-10 items-start mt-4">
        <div
          class="lg:w-1/2 lg:mt-5"
          ref="featureSection"
          v-motion="motionFadeUp"
        >
          <img
            src="../assets/images/architect2.jpg"
            alt="Why Choose Gnobone Vision"
            class="w-full h-1/4 object-cover"
          />
        </div>

        <div class="lg:w-1/2">
          <h1 class="text-lg font-bold mt-8 lg:text-start text-black">
            WHY CHOOSE GNOBONE VISION?
          </h1>
          <div class="flex items-center gap-5 mt-6">
            <div>
              <i class="fa-solid fa-lightbulb text-3xl text-blue"></i>
            </div>
            <div>
              <h1 class="text-sm mb-2 text-main_blue">Innovative Designs</h1>
              <p class="text-2xl text-black">
                Our projects reflect creativity, precision, and functionality.
              </p>
            </div>
          </div>
          <div class="flex items-center gap-5 mt-6">
            <div>
              <i class="fa-solid fa-briefcase text-3xl text-blue"></i>
            </div>
            <div>
              <h1 class="text-sm mb-2 text-main_blue">
                Professional Expertise
              </h1>
              <p class="text-2xl text-black">
                A team of qualified professionals with years of experience in
                architecture, design, and construction.
              </p>
            </div>
          </div>
          <div class="flex items-center gap-5 mt-6">
            <div>
              <i class="fa-solid fa-user-tie text-3xl text-blue"></i>
            </div>
            <div>
              <h1 class="text-sm mb-2 text-main_blue">
                Client-Centric Approach
              </h1>
              <p class="text-2xl text-black">
                We work closely with our clients to understand their vision and
                bring it to life.
              </p>
            </div>
          </div>
          <div class="flex items-center gap-5 mt-6">
            <div>
              <i class="fa-solid fa-leaf text-3xl text-blue"></i>
            </div>
            <div>
              <h1 class="text-sm mb-2 text-main_blue">Sustainable Practices</h1>
              <p class="text-2xl text-black">
                We are committed to sustainable construction practices, ensuring
                our projects positively impact the environment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { motionFadeUp } from "@/motion";
import { onMounted, ref } from "vue";
export default {
  setup() {
    const featureSection = ref(null);

    onMounted(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.style.transition = "opacity 2s, transform 2s";
              entry.target.style.opacity = "1";
              entry.target.style.transform = "translateY(0)";
            } else {
              entry.target.style.opacity = "0";
              entry.target.style.transform = "translateY(0)";
            }
          });
        },
        {
          threshold: 0.1, // Adjust as needed
        }
      );

      observer.observe(featureSection.value);
    });

    return {
      motionFadeUp,
      featureSection,
    };
  },
};
</script>

<style scoped>
.text-sm {
  font-size: 20px !important;
}
.text-2xl {
  font-size: 14px !important;
  line-height: 20px !important;
}
</style>
