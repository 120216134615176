<template>
  <main>
    <div class="container max-w-screen-2xl mx-auto px-4 md:px-10">
      <!-- Cards Grid -->
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 py-20">
        <div
          v-for="(card, index) in cards"
          :key="index"
          ref="serviceCards"
          class="service-card cursor-pointer flex flex-col items-center text-center bg-white text-black rounded-lg shadow-md hover:shadow-lg hover:transition-transform hover:duration-300 hover:-translate-y-2 p-6"
        >
          <!-- Icon -->
          <div class="icon-container text-blue h-20 w-20 flex items-center justify-center mb-4">
            <i :class="card.icon" class="text-5xl text-blue-700"></i>
          </div>

          <!-- Title -->
          <h3 class="title text-lg font-bold mt-2 text-black">{{ card.title }}</h3>

          <!-- Description -->
          <div class="description mt-4">
            <p v-if="!Array.isArray(card.description)" class="text-gray-600 text-sm">
              {{ card.description }}
            </p>
            <ul v-else class="text-gray-600 text-sm text-left list-disc pl-6">
              <li v-for="(item, idx) in card.description" :key="idx">{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          title: "Our Vision",
          description:
            "To be a leading force in transforming the real estate landscape by providing visionary designs and innovative construction solutions that enhance the quality of life for individuals and businesses alike.",
          icon: "fa-solid fa-lightbulb",
        },
        {
          title: "Our Mission",
          description:
            "To consistently deliver top-tier construction services and architectural designs that meet and exceed client expectations, while adhering to the highest standards of quality, safety, and sustainability.",
          icon: "fa-solid fa-bullseye",
        },
        {
          title: "Our Core Services",
          description: [
            "Architectural Drawings: Expertly crafted designs tailored to client needs.",
            "3D Visualization: High-quality 3D renderings that bring designs to life before construction begins.",
            "Real Estate Construction: Comprehensive construction services from concept to completion.",
          ],
          icon: "fa-solid fa-clipboard-list",
        },
      ],
    };
  },
  mounted() {
    const serviceCards = this.$refs.serviceCards;
    if (Array.isArray(serviceCards)) {
      serviceCards.forEach((card, index) => {
        setTimeout(() => {
          card.classList.add("fade-in");
        }, index * 200);
      });
    } else if (serviceCards) {
      serviceCards.classList.add("fade-in");
    }
  },
};
</script>

<style scoped>
.container {
  padding: 1rem;
}

.grid {
  display: grid;
  gap: 2.5rem; /* Controls the space between grid items */
}

.service-card {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  will-change: opacity, transform;
  min-height: 200px; /* Ensures consistent card height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-card.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.icon-container {
  flex-shrink: 0;
}

.title {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  flex-grow: 1;
}

.text-blue-700 {
  color: #0e3a8f;
}
</style>
