<template>
  <main class="bg-white py-10">
    <!-- Section for architectural designs -->
    <div class="md:px-10 max-w-screen-2xl mx-auto px-4">
      <!-- Header -->
      <div class="flex items-center justify-center">
        <div class="text-center">
          <div class="flex items-center justify-center">
            <img
              class="h-10 w-10"
              src="../assets/images/gnobonelogo.png"
              alt="Brand logo"
            />
            <h2 class="text-sm text-blue font-semibold -mb-7">
              Sketch Projects
            </h2>
          </div>
        </div>
      </div>

      <!-- Slider for Designs -->
      <div class="md:px-4 max-w-screen-2xl mx-auto py-10">
        <div class="flex justify-between items-center gap-6 relative">
          <!-- Design Card 1 -->
          <div
            v-for="(design, index) in designs.slice(0, 1)"
            :key="design.id"
            class="flex-1 cursor-pointer text-center py-4 px-4 rounded-lg shadow-lg transition-all duration-500 hover:scale-105 hover:shadow-xl"
          >
            <!-- Animation Video -->
            <video
              class="w-full h-80 object-cover transition-transform duration-300 hover:scale-105"
              autoplay
              muted
              loop
              playsinline
            >
              <source :src="design.video" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <!-- Design Card 2 -->
          <div
            v-for="(design, index) in designs.slice(1, 2)"
            :key="design.id"
            class="flex-1 cursor-pointer text-center py-4 px-4 rounded-lg shadow-lg transition-all duration-500 hover:scale-105 hover:shadow-xl"
          >
            <!-- Animation Video -->
            <video
              class="w-full h-80 object-cover transition-transform duration-300 hover:scale-105"
              autoplay
              muted
              loop
              playsinline
            >
              <source :src="design.video" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      cardsPerSlide: 3, // Adjust according to the number of cards per slide (3 cards in this case)
      designs: [
        {
          id: 1,
          video: require("../assets/videos/sketch 2.mp4"),
        },
        {
          id: 2,
          video: require("../assets/videos/V SKETCH-3.mp4"),
        },
      ],
      slideInterval: null, // To store interval for automatic sliding
    };
  },
  mounted() {
    // Set up automatic slide change every 3 seconds
    this.slideInterval = setInterval(this.nextSlide, 3000);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.slideInterval);
  },
  methods: {
    nextSlide() {
      if (
        this.activeIndex <
        Math.ceil(this.designs.length / this.cardsPerSlide) + 1
      ) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0; // Go back to the first set of slides
      }
    },
    prevSlide() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        this.activeIndex =
          Math.ceil(this.designs.length / this.cardsPerSlide) - 1; // Go to the last set of slides
      }
    },
  },
};
</script>

<style scoped>
/* Custom styling */
.carousel-wrapper {
  display: flex;
  gap: 6px;
  transition: transform 0.5s ease-in-out;
}

img {
  transition: transform 0.3s ease-in-out;
}

img:hover {
  transform: scale(1.05);
}
</style>
