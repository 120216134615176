<template>
  <main>
    <div
      class="relative bg-cover bg-center"
      :style="{
        backgroundImage: `url(${require('../assets/images/crop-architect-opening-blueprint.jpg')})`,
      }"
    >
      <div class="absolute inset-0 bg-black bg-opacity-50"></div>
      <div
        class="relative md:px-32 sm:px-12 max-w-screen-2xl mx-auto z-10 h-screen flex items-center justify-start text-white"
      >
        <!-- About Section -->
        <h1 class="text-2xl md:text-6xl font-bold">About Us</h1>
      </div>
    </div>

    <!-- Company profile -->
     <companyProfile />

     <!-- Why Choose Gnobone Visions -->
      <whyGnoboneVisions />
  </main>
</template>

<script>
import companyProfile from '@/components/companyProfile.vue';
import whyGnoboneVisions from '@/components/whyGnoboneVisions.vue';

export default {
    components: {
    companyProfile,
    whyGnoboneVisions,
  },
};
</script>

<style scoped></style>
