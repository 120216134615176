<template>
  <main class="bg-gray-50 py-12">
    <!-- Work Projects Section -->
    <div class="md:px-10 max-w-screen-2xl mx-auto px-4">
      <div class="flex items-center justify-start">
        <div class="text-center">
          <div class="flex items-center justify-center">
            <img
              class="h-10 w-10"
              src="../assets/images/gnobonelogo.png"
              alt="Brand logo"
            />
            <h2 class="text-sm text-blue font-semibold -mb-8">Our Services</h2>
          </div>
        </div>
      </div>
    </div>

    <!-- Carousel -->
    <div class="md:px-10 max-w-screen-2xl mx-auto px-4 bg-gray-50">
      <div
        id="controls-carousel"
        class="relative w-full mt-16"
        data-carousel="static"
      >
        <!-- Carousel wrapper -->
        <div class="relative h-auto overflow-hidden rounded-lg">
          <!-- Slide 1 -->
          <div
            v-show="activeSlide === 0"
            class="duration-700 ease-in-out grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
          >
            <!-- Card 1 -->
            <div
              class="relative bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300"
            >
              <video
                class="w-full h-72 object-cover"
                autoplay
                muted
                loop
                playsinline
              >
                <source
                  src="https://res.cloudinary.com/dngbejxfo/video/upload/v1733843513/HOSPTIAL-1_eoxhvj.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <div
                class="absolute bottom-4 left-4 bg-white p-2 rounded-lg shadow-lg flex justify-between items-center w-10/12"
              >
                <div>
                  <h3 class="text-lg font-bold text-blue">Animations</h3>
                  <!-- <p class="text-gray-500 text-sm">
                    Comprehensive construction
                  </p> -->
                </div>
                <router-link to="/services" class="bg-[#ffa200] p-3 rounded-full shadow-md">
                  <svg
                    class="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 12h14M12 5l7 7-7 7"
                    />
                  </svg>
                </router-link>
              </div>
            </div>

            <!-- Card 2 -->
            <div
              class="relative bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300"
            >
              <img
                class="w-full h-72 object-cover"
                src="../assets/images/3d-exterior.jpg"
                alt="3D Exterior Design"
              />
              <div
                class="absolute bottom-4 left-4 bg-white p-2 rounded-lg shadow-lg flex justify-between items-center w-10/12"
              >
                <div>
                  <h3 class="text-lg font-bold text-blue">Exterior 3D</h3>
                  <!-- <p class="text-gray-500 text-sm">
                    High-quality 3D renderings
                  </p> -->
                </div>
                <router-link to="/services" class="bg-[#ffa200] p-3 rounded-full shadow-md">
                  <svg
                    class="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 12h14M12 5l7 7-7 7"
                    />
                  </svg>
                </router-link>
              </div>
            </div>

            <!-- Card 3 -->
            <div
              class="relative bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300"
            >
              <video
                class="w-full h-72 object-cover"
                autoplay
                muted
                loop
                playsinline
              >
                <source src="../assets/videos/sketch 2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div
                class="absolute bottom-4 left-4 bg-white p-2 rounded-lg shadow-lg flex justify-between items-center w-10/12"
              >
                <div>
                  <h3 class="text-lg font-bold text-blue">Sketch</h3>
                  <!-- <p class="text-gray-500 text-sm">Expertly crafted designs</p> -->
                </div>
                <router-link to="/services" class="bg-[#ffa200] p-3 rounded-full shadow-md">
                  <svg
                    class="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 12h14M12 5l7 7-7 7"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>

          <!-- Slide 2 -->
          <div
            v-show="activeSlide === 1"
            class="duration-700 ease-in-out grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
          >
            <!-- Card 1 -->
            <div
              class="relative bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300"
            >
              <img
                class="w-full h-72 object-cover"
                src="../assets/images/dining-area.jpg"
                alt="3D Abstract Design"
              />
              <div
                class="absolute bottom-4 left-4 bg-white p-2 rounded-lg shadow-lg flex justify-between items-center w-10/12"
              >
                <div>
                  <h3 class="text-lg font-bold text-blue">Interior 3D</h3>
                  <!-- <p class="text-gray-500 text-sm">
                    High-quality 3D renderings
                  </p> -->
                </div>
                <router-link to="/services" class="bg-[#ffa200] p-3 rounded-full shadow-md">
                  <svg
                    class="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 12h14M12 5l7 7-7 7"
                    />
                  </svg>
                </router-link>
              </div>
            </div>

            <!-- Card 2 -->
            <div
              class="relative bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300"
            >
              <img
                class="w-full h-72 object-cover"
                src="../assets/images/creative-architect.jpg"
                alt="3D Abstract Design"
              />
              <div
                class="absolute bottom-4 left-4 bg-white p-2 rounded-lg shadow-lg flex justify-between items-center w-10/12"
              >
                <div>
                  <h3 class="text-lg font-bold text-blue">Tutorials</h3>
                  <!-- <p class="text-gray-500 text-sm">
                    High-quality 3D renderings
                  </p> -->
                </div>
                <router-link to="/services" class="bg-[#ffa200] p-3 rounded-full shadow-md">
                  <svg
                    class="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 12h14M12 5l7 7-7 7"
                    />
                  </svg>
                </router-link>
              </div>
            </div>

            <!-- Card 3 -->
            <div
              class="relative bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-all duration-300"
            >
              <video
                class="w-full h-72 object-cover"
                autoplay
                muted
                loop
                playsinline
              >
                <source src="../assets/videos/PROJECT 1.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div
                class="absolute bottom-4 left-4 bg-white p-2 rounded-lg shadow-lg flex justify-between items-center w-10/12"
              >
                <div>
                  <h3 class="text-lg font-bold text-blue">
                    Structural Projects
                  </h3>
                  <!-- <p class="text-gray-500 text-sm">Expertly crafted designs</p> -->
                </div>
                <router-link to="/services" class="bg-[#ffa200] p-3 rounded-full shadow-md">
                  <svg
                    class="h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 12h14M12 5l7 7-7 7"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <!-- Slider controls -->
        <button
          type="button"
          class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          @click="prevSlide"
        >
          <span
            class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50"
          >
            <svg
              class="w-4 h-4 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 1L1 5l4 4"
              />
            </svg>
            <span class="sr-only">Previous</span>
          </span>
        </button>
        <button
          type="button"
          class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          @click="nextSlide"
        >
          <span
            class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50"
          >
            <svg
              class="w-4 h-4 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 9l4-4-4-4"
              />
            </svg>
            <span class="sr-only">Next</span>
          </span>
        </button>
      </div>

      <!-- See more projects button -->
      <div class="flex items-center justify-end mt-10">
        <router-link
          to="/services"
          class="inline-flex items-center transition-transform hover:scale-105 focus:outline-none px-6 py-3 bg-blue text-white rounded-full shadow-md hover:bg-[#859aca] text-sm"
        >
          See More Projects &nbsp;
          <i class="fa-regular fa-circle-check"></i>
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      activeSlide: 0,
      totalSlides: 2, // Update this to match the number of slides
    };
  },
  methods: {
    nextSlide() {
      this.activeSlide = (this.activeSlide + 1) % this.totalSlides;
      console.log("Next Slide:", this.activeSlide);
    },
    prevSlide() {
      this.activeSlide =
        (this.activeSlide - 1 + this.totalSlides) % this.totalSlides;
      console.log("Previous Slide:", this.activeSlide);
    },
  },
};
</script>

<style>
/* Add any required styles here */
</style>
