<template>
  <footer class="relative bg-blue text-white py-4">
    <!-- Content -->
    <div class="relative z-10 max-w-screen-xl mx-auto px-6 md:px-12 lg:px-16">
      <!-- Upper Contact Us section -->
      <div class="flex justify-center mb-10 lg:-mt-24">
        <div
          class="w-4/5 lg:w-3/5 bg-[#859aca] rounded-xl p-8 shadow-lg text-center"
        >
          <p class="text-2xl font-semibold text-white mb-2">
            Ready to get started?
          </p>
          <p class="text-gray-300 mb-4">Talk to us today</p>
          <a
            :href="'https://wa.me/?text='"
            class="inline-flex items-center bg-blue text-white font-medium py-3 px-6 rounded-full shadow-lg transition-transform"
          >
            Let's Talk Online
            <i class="fa-regular fa-circle-check ml-2"></i>
          </a>
        </div>
      </div>

      <!-- Main footer section -->
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10">
        <!-- Logo Section -->
        <div>
          <a href="/">
            <img
              src="../assets/icons/blackwhite-logo.png"
              class="h-36 mx-auto lg:mx-0"
              alt="Gnobone Visions Logo"
            />
          </a>
        </div>

        <!-- Links -->
        <div>
          <h2 class="text-lg font-semibold text-gray-300 uppercase mb-4">
            Resources
          </h2>
          <ul class="space-y-2">
            <li>
              <router-link
                to="/about"
                class="text-gray-400 hover:text-white transition"
                >About Us</router-link
              >
            </li>
            <li>
              <router-link
                to="/services"
                class="text-gray-400 hover:text-white transition"
                >Our Services</router-link
              >
            </li>
          </ul>
        </div>

        <div>
          <h2 class="text-lg font-semibold text-gray-300 uppercase mb-4">
            Follow Us
          </h2>
          <ul class="space-y-2">
            <li>
              <a
                href="https://m.facebook.com/enobong.benson/"
                class="text-gray-400 hover:text-white transition"
                >Facebook</a
              >
            </li>
            <li>
              <a
                href="https://www.instagram.com/gnobone_visions/"
                class="text-gray-400 hover:text-white transition"
                >Instagram</a
              >
            </li>
            <li>
              <a
                href="https://www.threads.net/@gnobone_visions"
                class="text-gray-400 hover:text-white transition"
                >Thread</a
              >
            </li>
          </ul>
        </div>

        <div>
          <h2 class="text-lg font-semibold text-gray-300 uppercase mb-4">
            Legal
          </h2>
          <ul class="space-y-2">
            <li>
              <a href="#" class="text-gray-400 hover:text-white transition"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a href="#" class="text-gray-400 hover:text-white transition"
                >Terms &amp; Conditions</a
              >
            </li>
          </ul>
        </div>
      </div>

      <!-- Divider -->
      <hr class="border-white my-3" />

      <!-- Social Media and Footer Note -->
      <div
        class="flex flex-col lg:flex-row justify-between items-center text-gray-400"
      >
        <span
          >© 2024 <a href="/" class="hover:text-white">Gnobone Visions™</a>. All
          Rights Reserved.</span
        >
        <div class="flex space-x-6 mt-4 lg:mt-0">
          <a href="https://m.facebook.com/enobong.benson/" target="_blank" class="hover:text-white transition"
            ><i class="fab fa-facebook-f"></i
            ><span class="sr-only">Facebook</span></a
          >
          <a href="https://www.instagram.com/gnobone_visions/" target="_blank" class="hover:text-white transition"
            ><i class="fab fa-instagram"></i
            ><span class="sr-only">Instagram</span></a
          >
          <a href="https://www.threads.net/@gnobone_visions" target="_blank" class="hover:text-white transition">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              class="w-6 h-6"
              aria-hidden="true"
            >
              <path
                d="M331.5 235.7c2.2 .9 4.2 1.9 6.3 2.8c29.2 14.1 50.6 35.2 61.8 61.4c15.7 36.5 17.2 95.8-30.3 143.2c-36.2 36.2-80.3 52.5-142.6 53h-.3c-70.2-.5-124.1-24.1-160.4-70.2c-32.3-41-48.9-98.1-49.5-169.6V256v-.2C17 184.3 33.6 127.2 65.9 86.2C102.2 40.1 156.2 16.5 226.4 16h.3c70.3 .5 124.9 24 162.3 69.9c18.4 22.7 32 50 40.6 81.7l-40.4 10.8c-7.1-25.8-17.8-47.8-32.2-65.4c-29.2-35.8-73-54.2-130.5-54.6c-57 .5-100.1 18.8-128.2 54.4C72.1 146.1 58.5 194.3 58 256c.5 61.7 14.1 109.9 40.3 143.3c28 35.6 71.2 53.9 128.2 54.4c51.4-.4 85.4-12.6 113.7-40.9c32.3-32.2 31.7-71.8 21.4-95.9c-6.1-14.2-17.1-26-31.9-34.9c-3.7 26.9-11.8 48.3-24.7 64.8c-17.1 21.8-41.4 33.6-72.7 35.3c-23.6 1.3-46.3-4.4-63.9-16c-20.8-13.8-33-34.8-34.3-59.3c-2.5-48.3 35.7-83 95.2-86.4c21.1-1.2 40.9-.3 59.2 2.8c-2.4-14.8-7.3-26.6-14.6-35.2c-10-11.7-25.6-17.7-46.2-17.8H227c-16.6 0-39 4.6-53.3 26.3l-34.4-23.6c19.2-29.1 50.3-45.1 87.8-45.1h.8c62.6 .4 99.9 39.5 103.7 107.7l-.2 .2zm-156 68.8c1.3 25.1 28.4 36.8 54.6 35.3c25.6-1.4 54.6-11.4 59.5-73.2c-13.2-2.9-27.8-4.4-43.4-4.4c-4.8 0-9.6 .1-14.4 .4c-42.9 2.4-57.2 23.2-56.2 41.8l-.1 .1z"
              ></path>
            </svg>
            <span class="sr-only">Threads</span>
          </a>
          <!-- <a href="#" class="hover:text-white transition"><i class="fab fa-github"></i><span class="sr-only">GitHub</span></a> -->
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
footer {
  font-family: "Inter", sans-serif;
}

footer a {
  transition: color 0.3s ease;
}

svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: currentColor; /* Matches text color */
}

</style>
