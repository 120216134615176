import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import ServicesPage from "@/views/servicesPage.vue";
import AboutPage from "@/views/AboutPage.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomePage,
    },
    {
      path: "/services",
      name: "services",
      component: ServicesPage,
    },
    {
      path: "/about",
      name: "about",
      component: AboutPage
    }
  ],
});

export default router;
