export const motionFadeUp = {
    initial: {
      opacity: 0,
      y: 200,
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 2000,
      },
    },
  };
  